import { FC, PropsWithChildren } from "react";
import styles from "./label.module.sass";

interface ILabelProps {
  labelText: string;
  isRequired?: boolean;
}

const Label: FC<PropsWithChildren<ILabelProps>> = ({labelText, isRequired, children}) => {
  return (
    <div className={styles.labelContainer}>
      <label className={styles.label}>{labelText} {isRequired ? <span>*</span> : ""}</label>
      {children}
    </div>
  )
}

export default Label;