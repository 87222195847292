
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { Reducers } from "../types/types";

import { generalSlice, uiSlice } from "../features";



export const store = configureStore({
  reducer: {
    [Reducers.planner]: generalSlice.reducer,
    [Reducers.ui]: uiSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
