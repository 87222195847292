import { BrowserRouter, Route, Routes } from "react-router-dom";
import Router from './Router/Router';
import styles from "./app.module.sass"
import { useAppDispatch } from "store";
import { user } from "features";
import { useEffect } from "react";
import { LoaderScreen, Snacks } from "components";


const App = () => {
  const dispatch = useAppDispatch()

  const login = () => {
    // dispatch(user({ userData: 'query_id=AAEH3rsaAAAAAAfeuxqg1qIc&user=%7B%22id%22%3A448519687%2C%22first_name%22%3A%22%D0%95%D0%B2%D0%B3%D0%B5%D0%BD%D0%B8%D0%B9%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22EvPanchenko%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1727201596&hash=40d8802516bdf38d69952825702a1c302d3c3315ba22df7a59047f017d258325' }));
    dispatch(user({userData: window.Telegram.WebApp.initData}));
  };

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      login();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <div className={styles.layout}>
        <LoaderScreen />
        <Snacks />
        <Routes>
          <Route path="/*" element={<Router />} />
          {/* <Route path="/404" element={<NotFoundPage />} /> */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
