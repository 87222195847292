import { useEffect, useRef, useState } from "react";
import Calendar from '@event-calendar/core';
import ListPlugin from '@event-calendar/list';
import TimePlugin from '@event-calendar/time-grid';
import SwitchSelector from "react-switch-selector";
import '@event-calendar/core/index.css';
import "./eventCalendar.sass";
import { useAppSelector } from "store";
import { tasksSelector } from "features";

type TView = 'timeGridDay' | 'listWeek'

const options = [
  {
    label: "День",
    value: "timeGridDay",
  },
  {
    label: "Неделя",
    value: "listWeek",
  }
];

const EventCalendar = () => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const [currentView, setCurrentView] = useState<TView>('timeGridDay');
  const { result: tasks } = useAppSelector(tasksSelector)

  useEffect(() => {
    if (calendarRef.current) {
      const calendar = new Calendar({
        target: calendarRef.current,
        props: {
          plugins: [ListPlugin, TimePlugin],
          options: {
            events: tasks?.length ? tasks?.map((task) => ({
              title: {
                html:
                  `<div class='taskInfo'>
                    ${task.taskName}
                    ${!!task.comment ? `<p class=${'taskComment'}>${task.comment}</p>` : ''}
                    </div>`
              },
              start: new Date(task.dateStart),
              end: new Date(task.dateEnd),
            })) : [],
            view: currentView,
            locale: 'ru',
            buttonText: {
              today: "Сегодня",
            },
            slotDuration: '00:15',
            noEventsContent: "Нет задач",
            slotHeight: 70,
          }
        }
      });

      return () => {
        calendar.destroy();
      };
    }
  }, [currentView, tasks]);

  const onChange = (newValue: TView) => {
    setCurrentView(newValue);
  };

  return (
    <div>
      <div className={"switchContainer"}>
        <SwitchSelector
          onChange={(value) => onChange(value as TView)}
          options={options}
          backgroundColor={window.Telegram.WebApp.themeParams?.section_separator_color}
          fontColor={"#333"}
          selectedBackgroundColor={window.Telegram.WebApp.themeParams?.button_color}
          selectedFontColor={window.Telegram.WebApp.themeParams?.button_text_color}
          wrapperBorderRadius={6}
          optionBorderRadius={6}
          selectionIndicatorMargin={2}

        />
      </div>
      <div ref={calendarRef} />
    </div>
  );
};

export default EventCalendar;
