import { Form } from "react-bootstrap";
import styles from "./switch.module.sass"

interface ISwitchProps {
  label: string;
  onChange: (value: boolean) => void;
  isChecked: boolean;
  isDisabled?: boolean;
}

const Switch = ({ label, onChange, isDisabled, isChecked }: ISwitchProps) => {
  return (
    <Form.Check
      type="switch"
      label={label}
      className={styles.switch}
      onChange={(e) => onChange(e.target.checked)}
      disabled={isDisabled}
      checked={isChecked}
    />
  )
}

export default Switch;