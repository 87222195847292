import { tasks, tasksSelector, userSelector } from "features";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "store";
import styles from "./loaderScreen.module.sass";

const LoaderScreen = () => {
  const dispatch = useAppDispatch();
  const timerRef = useRef<NodeJS.Timeout>();
  const [isLoading, setLoading] = useState(true);
  const { success: loginSuccess, fetching: loginFetching } = useAppSelector(userSelector)
  const { success: tasksSuccess, fetching: tasksFetching } = useAppSelector(tasksSelector)
  const token = localStorage.getItem("token")

  useEffect(() => {
    document.body.style.overflow = "hidden"
    timerRef.current = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (token || loginSuccess) {
      dispatch(tasks({}))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess, token])

  if ((loginSuccess || !loginFetching || !token || !tasksFetching || tasksSuccess) && !isLoading) {
    document.body.style.overflow = "";
    return null
  }



  return (
    <div className={styles.loadingScreen}>
      <span className={styles.appName}>Planner</span>
      <div className={styles.spinners}>
        <Spinner animation="grow" variant="primary" className={styles.spinner} />
        <Spinner animation="grow" variant="primary" className={styles.spinner} />
        <Spinner animation="grow" variant="primary" className={styles.spinner} />
      </div>
    </div>
  )
}

export default LoaderScreen;
